<template>
  <div class="signature">
    <div class="tile mb-2">
      <div class="nav nav-tabs" id="signatureTabs" role="tablist">
        <button
          class="nav-link active"
          id="templates-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-templates"
          type="button"
          role="tab"
          aria-controls="nav-templates"
          aria-selected="true"
        >
          Templates
        </button>
        <button
          class="nav-link"
          id="nav-instructions-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-instructions"
          type="button"
          role="tab"
          aria-controls="nav-instructions"
          aria-selected="false"
        >
          Instructions
        </button>
      </div>
    </div>
    <div class="tab-content" id="signatureContent">
      <div
        class="tab-pane fade show active"
        id="nav-templates"
        role="tabpanel"
        aria-labelledby="nav-templates-tab"
      >
        <ValidationObserver
          ref="signatureForm"
          tag="div"
          v-slot="{ handleSubmit }"
          class="mb-2"
          v-if="showRiskInput"
        >
          <div class="tile">
            <h5 class="tile-title">Risk Percentage</h5>
            <div class="tile-body">
              <form>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <ValidationProvider
                        tag="div"
                        rules="required"
                        name="Risk"
                        v-slot="{ errors }"
                        class="mb-3 pt-1"
                      >
                        <label for="risk" class="col-form-label">Risk:</label>
                        <input
                          type="text"
                          v-model="risk"
                          id="risk"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0],
                          }"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="d-grid d-md-flex justify-content-md-end">
              <button
                class="btn btn-primary"
                type="button"
                @click.prevent="handleSubmit(editRisk)"
              >
                Update Risk
                <loader :isLoading="isLoading"></loader>
              </button>
            </div>
          </div>
        </ValidationObserver>
        <div class="tile">
          <h5 class="tile-title mb-4">Templates</h5>
          <div class="accordion" id="accordionExample">
            <div v-if="checkBranch('EDR,EDR/BENOR,ALL')" class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  EDR
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <signatureTemplate
                    signatureID="signatureEDRTemplate"
                    :signature="userLoggedIn"
                    license="edr"
                  ></signatureTemplate>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click.prevent="
                      generateSignature('signatureEDRTemplate', 'EDR')
                    "
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="checkBranch('BENOR,EDR/BENOR,ALL')"
              class="accordion-item"
            >
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Triomarkets Capital Ltd
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <signatureTemplate
                    signatureID="signatureBenorTemplate"
                    :signature="userLoggedIn"
                    license="benor"
                  ></signatureTemplate>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click.prevent="
                      generateSignature('signatureBenorTemplate', 'BENOR')
                    "
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="nav-instructions"
        role="tabpanel"
        aria-labelledby="nav-instructions-tab"
      >
        <div class="tile">
          <h5 class="tile-title mb-4">Templates</h5>
          <div class="row">
            <div class="col-md-12 text-center">
              <ol>
                <li>First, launch Outlook then select File > Options.</li>
                <img
                  class="py-4"
                  src="../assets/img/signature/instruction1.png"
                  alt="Outlook Options"
                />
                <li>Next select the Mail tab and then Signatures.</li>
                <img
                  class="py-4"
                  src="../assets/img/signature/instruction2.png"
                  alt
                />
                <li>
                  Select New and type in a name for the signature you’re
                  creating.
                </li>
                <img
                  class="py-4"
                  src="../assets/img/signature/instruction3.png"
                  alt
                />
                <li>
                  Paste the signature into the text area showed on instruction
                  below:
                </li>
                <img
                  class="py-4"
                  src="../assets/img/signature/instruction4.png"
                  alt
                />
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "../components/loader";
import { getters, mutations } from "../store";
import signatureTemplate from "../components/signatureTemplate";
export default {
  data() {
    return {
      risk: "",
      isLoading: false,
    };
  },
  components: { loader, signatureTemplate },
  computed: {
    userLoggedIn() {
      return getters.userLoggedIn();
    },
    showRiskInput() {
      return getters.isAdmin() || this.userLoggedIn.department === "Compliance";
    },
  },
  watch: {
    userLoggedIn: {
      handler({ risk }) {
        this.risk = risk;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    editRisk() {
      this.isLoading = true;
      this.$axios
        .post(`/users/editRiskPercentage`, { risk: this.risk })
        .then(({ data }) => {
          if (data.success) {
            mutations.updateUserRiskPercentage(this.risk);
          }
          window.scrollTo(0, 0);
          this.isLoading = false;
          mutations.showToast(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    generateSignature(templateID, templateName) {
      const elm = document.getElementById(templateID);
      // Internet Explorer
      if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(elm);
        range.select();
        document.execCommand("copy");
      } else if (window.getSelection) {
        // other browsers
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(elm);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
      }
      window.scrollTo(0, 0);
      mutations.showToast({
        success: true,
        message: `${templateName} template has been successfully copied`,
      });
    },
    checkBranch(branches) {
      const selectedBranches = branches.split(",");
      return selectedBranches.some((el) =>
        this.userLoggedIn.branch?.includes(el)
      );
    },
  },
};
</script>
